import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { setIsLoading } from "../../actions";
import { useDispatch } from "react-redux";
import PhoneNumber from "./components/PhoneNumber";

function PersonalInformation() {
  const [searchParams] = useSearchParams();
  const serialNumber = searchParams.get("sn");
  const userAddress = JSON.parse(localStorage.getItem("userAddress"));

  const handleEmergencyPhoneNumber = (phoneNumber) => {
    setPhoneEmergency(phoneNumber);
  };

  const [fName, setFName] = useState("");
  const [mName, setMName] = useState("");
  const [lName, setLname] = useState("");
  const [toggleSex, setToggleSex] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [unit, setUnit] = useState(null);
  const [street, setStreet] = useState("");
  const [city, setCity] = useState(userAddress?.city || userAddress?.town);
  const [stateOrProvince, setStateOrProvince] = useState(userAddress?.state);
  const [country, setCountry] = useState(userAddress?.country);
  const [postalCode, setPostalCode] = useState(userAddress?.postcode);
  const [backupEmail, setBackUpEmail] = useState("");
  const [isValidBackupEmail, setIsValidBackupEmail] = useState(true);
  const [piErrors, setPiErrors] = useState({});

  //Emergency Contact
  const [fullNameEmergency, setFullNameEmergency] = useState("");
  const [unitEmergency, setUnitEmergency] = useState(null);
  const [streetEmergency, setStreetEmergency] = useState("");
  const [cityEmergency, setCityEmergency] = useState(
    userAddress?.city || userAddress?.town
  );
  const [phoneEmergency, setPhoneEmergency] = useState(null);
  const [emailEmergency, setEmailEmergency] = useState("");
  const [isValidEmergencyEmail, setIsValidEmergencyEmail] = useState(true);
  const [ecErrors, setEcErrors] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const isMinor = () => {
    const today = new Date();
    const checkBirthDate = new Date(birthDate);
    let age = today.getFullYear() - checkBirthDate.getFullYear();
    const monthDifference = today.getMonth() - checkBirthDate.getMonth();

    // Adjust age if the birthdate hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < checkBirthDate.getDate())
    ) {
      age--;
    }

    return age < 18;
  };

  const validatePersonalInfo = () => {
    let rtrn = true;
    const newErrors = {};

    if (!fName) {
      newErrors.firstName = "First name is required.";
      rtrn = false;
    }
    if (!lName) {
      newErrors.lastName = "Last name is required.";
      rtrn = false;
    }
    if (!toggleSex) {
      newErrors.sex = "Gender is required.";
      rtrn = false;
    }
    if (!birthDate) {
      newErrors.birthDate = "Birth date is required.";
      rtrn = false;
    }
    if (!birthPlace) {
      newErrors.birthPlace = "Birth place is required.";
      rtrn = false;
    }
    if (!unit) {
      newErrors.unitNumber = "Unit number is required.";
      rtrn = false;
    }
    if (!street) {
      newErrors.street = "Street is required.";
      rtrn = false;
    }
    if (!city) {
      newErrors.city = "City is required.";
      rtrn = false;
    }
    if (!stateOrProvince) {
      newErrors.stateOrProvince = "State or province is required.";
      rtrn = false;
    }
    if (!country) {
      newErrors.country = "Country is required.";
      rtrn = false;
    }
    if (!postalCode) {
      newErrors.postalCode = "Postal code is required.";
      rtrn = false;
    }
    if (!backupEmail) {
      newErrors.backupEmail = "Backup email is required.";
      rtrn = false;
    }

    setPiErrors(newErrors);

    return rtrn;
  };

  const validateEmergencyContact = () => {
    let rtrn = true;
    const newErrors = {};

    if (!fullNameEmergency) {
      newErrors.fullNameEmergency = "Full name is required.";
      rtrn = false;
    }
    if (!unitEmergency) {
      newErrors.unitEmergency = "Unit number is required.";
      rtrn = false;
    }
    if (!streetEmergency) {
      newErrors.streetEmergency = "Street is required.";
      rtrn = false;
    }
    if (!cityEmergency) {
      newErrors.cityEmergency = "City is required.";
      rtrn = false;
    }
    if (!phoneEmergency) {
      newErrors.phoneEmergency = "Phone number is required.";
      rtrn = false;
    }
    if (!emailEmergency) {
      newErrors.emailEmergency = "Email is required.";
      rtrn = false;
    }

    setEcErrors(newErrors);

    return rtrn;
  };

  const submitForm = async (formData, formDataEmergencyContact) => {
    const isValidPI = validatePersonalInfo();
    const isValidEC = validateEmergencyContact();
    if (!isValidPI || !isValidEC) {
      return;
    }

    dispatch(setIsLoading(true));
    let configPersonalInfo = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/api/profile`,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: formData,
    };
    let configEmergencyContact = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/api/emergency-contacts`,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: formDataEmergencyContact,
    };

    try {
      const [responsePersonalInfo, responseEmergencyContact] =
        await Promise.all([
          axios.request(configPersonalInfo),
          axios.request(configEmergencyContact),
        ]);
      console.log(responsePersonalInfo);
      console.log(responseEmergencyContact);

      if (
        responsePersonalInfo.status === 200 &&
        responseEmergencyContact.status === 201
      ) {
        user.is_profile_info_done = 1;
        localStorage.setItem(
          "user",
          JSON.stringify(responsePersonalInfo.data.user)
        );
        if (isMinor()) {
          localStorage.setItem("isMinor", true);
          navigate("/guardian-information");
          return;
        }
        navigate("/payment");
      }
    } catch (error) {
      console.error(error);
      switch (error.request.responseURL) {
        case `${process.env.REACT_APP_API_URL}/api/profile`:
          setPiErrors(error.response.data.errors);
          break;
        case `${process.env.REACT_APP_API_URL}/api/emergency-contacts`:
          setEcErrors(error.response.data.errors);
          break;
        default:
          console.error("Error confirming payment: ", error);
      }
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleNextBtn = () => {
    const formData = {
      // fullname: fullName,
      first_name: fName,
      middle_name: mName,
      last_name: lName,
      sex: toggleSex,
      dob: birthDate,
      place_of_birth: birthPlace,
      unit_number: unit,
      street_name: street,
      city: city,
      state: stateOrProvince,
      country: country,
      postal_code: postalCode,
      backup_email: backupEmail,
      token: localStorage.getItem("token"),
      is_profile_info_done: 1,
    };
    const formDataEmergencyContact = {
      city: cityEmergency,
      country: country,
      email: emailEmergency,
      fullname: `${first_name_emergency} ${last_name_emergency}`,
      phone_number: phoneEmergency,
      postal_code: postalCode,
      state: unitEmergency,
      street_name: streetEmergency,
    };
    console.log(formData);
    console.log(formDataEmergencyContact);
    submitForm(formData, formDataEmergencyContact);
  };

  const validateBackupEmail = (e) => {
    const newEmail = e.target.value;
    setBackUpEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidBackupEmail(emailRegex.test(newEmail));
  };
  const validateEmergencyEmail = (e) => {
    const newEmail = e.target.value;
    setEmailEmergency(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmergencyEmail(emailRegex.test(newEmail));
  };

  let first_name_emergency = "";
  let last_name_emergency = "";

  const fullNameParts = fullNameEmergency.split(" ");

  if (fullNameParts.length >= 1) {
    first_name_emergency = fullNameParts[0];
  }
  if (fullNameParts.length > 1) {
    last_name_emergency = fullNameParts.slice(1).join(" ");
  }
  console.log("First Name (Emergency):", first_name_emergency);
  console.log("Last Name (Emergency):", last_name_emergency);
  return (
    <div className="flex overflow-hidden flex-col px-10 pb-[180px] max-md:px-5 max-md:pb-24 bg-[#F9FAFB]">
      <div className="flex flex-col self-center mt-16 w-full max-w-[700px] max-md:mt-10 bg-white rounded-2xl p-8 border border-[#E5E7EB]">
        <div className="flex gap-4 mb-2 max-md:flex-col items-center justify-center max-md:gap-0 py-1 font-bold text-blue-900 leading-[120%] max-md:flex-wrap">
          <div className="flex-1 text-2xl tracking-tight max-md:max-w-full max-md:text-[20px]">
            Personal Information
          </div>
          <div className="text-3xl tracking-tight text-right">
            <span className="text-base tracking-tight leading-5 ">
              Serial Code
            </span>
            <span className="text-lg tracking-tight leading-6">
              {" "}
              {serialNumber}
            </span>
          </div>
        </div>
        <div className="flex flex-wrap gap-4 justify-center items-start mt-2 w-full text-base leading-none text-stone-300 max-md:flex-col">
          <div className="flex-1 self-stretch">
            <label
              htmlFor="first-name"
              className="block text-sm font-normal text-gray-700 mb-1"
            >
              First Name
            </label>
            <div className="shrink gap-2.5 rounded-lg border border-solid border-neutral-300">
              <input
                placeholder="e.g. John"
                className="text-black w-full px-3 rounded-lg min-h-[45px]"
                value={fName}
                onChange={(e) => setFName(e.target.value)}
              />
            </div>
            {piErrors.firstName && (
              <smal className="text-red-500 text-xs flex gap-1 mt-1">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z"
                    stroke="#F94B4B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {piErrors.firstName}
              </smal>
            )}
          </div>
          <div className="flex-1 self-stretch">
            <label
              htmlFor="middle-name"
              className="block text-sm font-normal text-gray-700 mb-1"
            >
              Middle Name
            </label>
            <div className="shrink gap-2.5 rounded-lg border border-solid border-neutral-300">
              <input
                placeholder="e.g. Watson"
                className="text-black w-full px-3 rounded-lg min-h-[45px]"
                value={mName}
                onChange={(e) => setMName(e.target.value)}
              />
            </div>
          </div>
          <div className="flex-1 self-stretch">
            <label
              htmlFor="last-name"
              className="block text-sm font-normal text-gray-700 mb-1"
            >
              Last Name
            </label>
            <div className="shrink gap-2.5 rounded-lg border border-solid border-neutral-300">
              <input
                placeholder="e.g. Smith"
                className="text-black w-full px-3 rounded-lg min-h-[45px]"
                value={lName}
                onChange={(e) => setLname(e.target.value)}
              />
            </div>
            {piErrors.lastName && (
              <smal className="text-red-500 text-xs flex gap-1 mt-1">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z"
                    stroke="#F94B4B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {piErrors.lastName}
              </smal>
            )}
          </div>
        </div>

        <div className="flex flex-wrap gap-4 justify-center items-start max-sm:mt-4 mt-2 w-full text-base leading-none text-stone-300 max-md:flex-col">
          <div className="flex-1 self-stretch">
            <label
              htmlFor="gender"
              className="block text-sm font-normal text-gray-700 mb-1"
            >
              Gender
            </label>
            <div className="flex gap-6 max-sm:gap-4 max-sm:px-2 text-base justify-start px-1 max-md:justify-start items-center my-auto whitespace-nowrap">
              <div
                className="flex gap-2 py-1 cursor-pointer items-center"
                onClick={() => setToggleSex("male")}
              >
                <div
                  className={` shrink-0 w-4 h-4  rounded-full ${
                    toggleSex === "male"
                      ? "bg-teal-400 text-slate-800"
                      : "bg-zinc-300"
                  }`}
                />
                <div
                  className={`text-base ${
                    toggleSex === "male"
                      ? "text-black font-medium"
                      : "text-gray-600"
                  }`}
                >
                  Male
                </div>
              </div>
              <div
                className="flex gap-2 py-1 cursor-pointer items-center"
                onClick={() => setToggleSex("female")}
              >
                <div
                  className={` shrink-0 w-4 h-4  rounded-full ${
                    toggleSex === "female"
                      ? "bg-teal-400 text-slate-800"
                      : "bg-zinc-300"
                  }`}
                />
                <div
                  className={`text-base ${
                    toggleSex === "female"
                      ? "text-black font-medium"
                      : "text-gray-600"
                  }`}
                >
                  Female
                </div>
              </div>
            </div>
            {piErrors.sex && (
              <smal className="text-red-500 text-xs flex gap-1 mt-1">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z"
                    stroke="#F94B4B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {piErrors.sex}
              </smal>
            )}
          </div>
          <div className="flex-1 self-stretch">
            <label
              htmlFor="birthdate"
              className="block text-sm font-normal text-gray-700 mb-1"
            >
              Birth Date
            </label>
            <div className="shrink gap-2.5 rounded-lg">
              <input
                type="date"
                s
                value={birthDate}
                onChange={(e) => setBirthDate(e.target.value)}
                className={`border border-solid border-neutral-300 ${
                  birthDate !== "" ? " text-black" : " text-zinc-300"
                } w-full px-3 rounded-lg min-h-[45px] `}
              />
            </div>
            {piErrors.birthDate && (
              <smal className="text-red-500 text-xs flex gap-1 mt-1">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z"
                    stroke="#F94B4B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {piErrors.birthDate}
              </smal>
            )}
          </div>
          <div className="flex-1 self-stretch">
            <label
              htmlFor="birthplace"
              className="block text-sm font-normal text-gray-700 mb-1"
            >
              Birthplace
            </label>
            <div className="shrink gap-2.5 rounded-lg border border-solid border-neutral-300">
              <input
                value={birthPlace}
                onChange={(e) => {
                  setBirthPlace(e.target.value);
                }}
                placeholder="e.g. New Orleans"
                className="text-black w-full px-3 rounded-lg min-h-[45px]"
              />
            </div>
            {piErrors.birthPlace && (
              <smal className="text-red-500 text-xs flex gap-1 mt-1">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z"
                    stroke="#F94B4B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {piErrors.birthPlace}
              </smal>
            )}
          </div>
        </div>

        <div className="mt-4 w-full text-base leading-6 text-neutral-600 max-md:max-w-full font-[600]">
          Address
        </div>
        <div className="flex flex-wrap gap-4 justify-center items-start mt-2 w-full text-base leading-none text-stone-300 max-md:flex-col">
          <div className="flex-1 self-stretch">
            <div className="shrink gap-2.5 rounded-lg border border-solid border-neutral-300">
              <input
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                placeholder="Unit#"
                className="text-black w-full px-3 rounded-lg min-h-[45px]"
              />
            </div>
            {piErrors.unitNumber && (
              <smal className="text-red-500 text-xs flex gap-1 mt-1">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z"
                    stroke="#F94B4B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {piErrors.unitNumber}
              </smal>
            )}
          </div>
          <div className="flex-1 self-stretch">
            <div className="shrink gap-2.5 rounded-lg border border-solid border-neutral-300">
              <input
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                placeholder="Street"
                className="text-black w-full px-3 rounded-lg min-h-[45px]"
              />
            </div>
            {piErrors.street && (
              <smal className="text-red-500 text-xs flex gap-1 mt-1">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z"
                    stroke="#F94B4B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {piErrors.street}
              </smal>
            )}
          </div>
          <div className="flex-1 self-stretch">
            <div className="shrink gap-2.5 rounded-lg border border-solid border-neutral-300">
              <input
                value={city}
                onChange={(e) => setCity(e.target.value)}
                placeholder="City"
                className="text-black w-full px-3 rounded-lg min-h-[45px]"
              />
            </div>
            {piErrors.city && (
              <smal className="text-red-500 text-xs flex gap-1 mt-1">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z"
                    stroke="#F94B4B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {piErrors.city}
              </smal>
            )}
          </div>
        </div>
        <div className="flex flex-wrap gap-4 justify-center items-start max-sm:mt-4 mt-2 w-full text-base leading-none text-stone-300 max-md:flex-col">
          <div className="flex-1 self-stretch">
            <div className="shrink gap-2.5 rounded-lg border border-solid border-neutral-300">
              <input
                value={stateOrProvince}
                onChange={(e) => setStateOrProvince(e.target.value)}
                placeholder="State/Province"
                className="text-black w-full px-3 rounded-lg min-h-[45px]"
              />
            </div>
            {piErrors.stateOrProvince && (
              <smal className="text-red-500 text-xs flex gap-1 mt-1">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z"
                    stroke="#F94B4B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {piErrors.stateOrProvince}
              </smal>
            )}
          </div>
          <div className="flex-1 self-stretch">
            <div className="shrink gap-2.5 rounded-lg border border-solid border-neutral-300">
              <input
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                placeholder="Country"
                className="text-black w-full px-3 rounded-lg min-h-[45px]"
              />
            </div>
            {piErrors.country && (
              <smal className="text-red-500 text-xs flex gap-1 mt-1">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z"
                    stroke="#F94B4B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {piErrors.country}
              </smal>
            )}
          </div>
          <div className="flex-1 self-stretch">
            <div className="shrink gap-2.5 rounded-lg">
              <input
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                placeholder="Postal Code"
                type="number"
                className="text-black w-full px-3 rounded-lg min-h-[45px] outline-none border border-solid border-neutral-300"
              />
            </div>
            {piErrors.postalCode && (
              <smal className="text-red-500 text-xs flex gap-1 mt-1">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z"
                    stroke="#F94B4B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {piErrors.postalCode}
              </smal>
            )}
          </div>
        </div>

        <div className="mt-4 w-full text-base leading-6 text-neutral-600 max-md:max-w-full font-[600]">
          Backup Email Address
        </div>
        <div className="flex flex-wrap gap-4 justify-between items-start mt-2 w-full text-base leading-none text-stone-300 max-md:flex-col">
          <div className="w-full rounded-lg border border-solid border-neutral-300">
            <input
              value={backupEmail}
              onChange={validateBackupEmail}
              placeholder="Email Address"
              className="text-black w-full px-3 rounded-lg min-h-[45px]"
            />
          </div>
        </div>
        {piErrors.backupEmail && (
          <smal className="text-red-500 text-xs flex gap-1 mt-1">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z"
                stroke="#F94B4B"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {piErrors.backupEmail}
          </smal>
        )}
        {!isValidBackupEmail && (
          <div className="text-red-600 text-sm mt-1 px-2">
            Please enter a valid email address.
          </div>
        )}

        <div className="flex flex-col p-4 mt-4 w-full text-sm tracking-normal leading-5 bg-[#E8EBF1] border border-[#1C3775] rounded-lg text-black text-opacity-80 max-md:max-w-full">
          <div className="max-md:max-w-full">
            <span className="text-base font-bold leading-6">
              Next of Kin or Emergency Person:
            </span>
            <br />
            <br />
            <span className="tracking-normal leading-5 text-black">
              As part of your membership sign-up, we ask you to provide the
              contact details of a family member or friend as an emergency
              contact.
            </span>
            <br />
            <br />
            <span className="font-semibold tracking-normal leading-5 text-black">
              Why is this important?
            </span>
            <br />
            <span className="tracking-normal leading-5 text-black">
              This contact will only be used in one specific situation: if you
              have unclaimed funds in your account. It's a precautionary measure
              to ensure that any unclaimed benefits can be appropriately managed
              and directed in case you're not reachable.
            </span>
            <br />
            <br />
            <span className="font-semibold tracking-normal leading-5 text-black">
              Respecting Your Privacy
            </span>
            <br />
            <span className="tracking-normal leading-5 text-black">
              Please be assured, this information is strictly for the purpose of
              handling unclaimed funds and will be kept confidential.
            </span>
            <br />
            <br />
            <span className="tracking-normal leading-5 text-black">
              Thank you for taking this step to secure your benefits and
              interests as a member of our community.
            </span>
          </div>
          <div className="mt-4 max-md:max-w-full">
            <span className="font-semibold tracking-normal leading-5 text-black">
              Commitment to Data Privacy and Security
            </span>
            <br />
            <span className="tracking-normal leading-5 text-black">
              At DevReg, we prioritize your privacy. Once your application is
              approved, your personal information is removed from our online
              servers and securely stored offline. This practice ensures the
              utmost security and confidentiality of your data.
            </span>
            <br />
            <br />
            <span className="tracking-normal leading-5 text-black">
              For any questions about our data management, please contact us.
            </span>
          </div>
        </div>
        <div className="mt-4 w-full text-base leading-6 text-neutral-600 max-md:max-w-full font-[600]">
          Full Name{" "}
          <span className="font-normal text-sm">
            (First, Middle, Last Name)
          </span>
        </div>
        <div>
          <input
            value={fullNameEmergency}
            onChange={(e) => setFullNameEmergency(e.target.value)}
            placeholder="e.g. John Watson Smith"
            className="text-black justify-center items-start p-2.5 mt-2 w-full text-base whitespace-nowrap rounded-lg border border-solid border-neutral-300 max-md:pr-5 max-md:max-w-full"
          />
          {ecErrors.fullNameEmergency && (
            <smal className="text-red-500 text-xs flex gap-1 mt-1">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z"
                  stroke="#F94B4B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {ecErrors.fullNameEmergency}
            </smal>
          )}
        </div>

        <div className="mt-4 w-full text-base leading-6 text-neutral-600 max-md:max-w-full font-[600]">
          Address
        </div>
        <div className="flex flex-wrap gap-4 justify-center items-start mt-2 w-full text-base leading-none text-stone-300 max-md:flex-col">
          <div className="flex-1 self-stretch">
            <div className="shrink gap-2.5 rounded-lg border border-solid border-neutral-300">
              <input
                value={unitEmergency}
                onChange={(e) => setUnitEmergency(e.target.value)}
                placeholder="Unit"
                className="text-black w-full px-3 rounded-lg min-h-[45px]"
              />
            </div>
            {ecErrors.unitEmergency && (
              <smal className="text-red-500 text-xs flex gap-1 mt-1">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z"
                    stroke="#F94B4B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {ecErrors.unitEmergency}
              </smal>
            )}
          </div>
          <div className="flex-1 self-stretch">
            <div className="flex-1 shrink gap-2.5 self-stretch rounded-lg border border-solid border-neutral-300">
              <input
                value={streetEmergency}
                onChange={(e) => setStreetEmergency(e.target.value)}
                placeholder="Street"
                className="text-black w-full px-3 rounded-lg min-h-[45px]"
              />
            </div>
            {ecErrors.streetEmergency && (
              <smal className="text-red-500 text-xs flex gap-1 mt-1">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z"
                    stroke="#F94B4B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {ecErrors.streetEmergency}
              </smal>
            )}
          </div>
          <div className="flex-1 self-stretch">
            <div className="flex-1 shrink gap-2.5 self-stretch rounded-lg border border-solid border-neutral-300">
              <input
                value={cityEmergency}
                onChange={(e) => setCityEmergency(e.target.value)}
                placeholder="City"
                className="text-black w-full px-3 rounded-lg min-h-[45px]"
              />
            </div>
            {ecErrors.cityEmergency && (
              <smal className="text-red-500 text-xs flex gap-1 mt-1">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z"
                    stroke="#F94B4B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {ecErrors.cityEmergency}
              </smal>
            )}
          </div>
        </div>
        <div className="mt-4 mb-[-4px] w-full text-base leading-6 text-neutral-600 max-md:max-w-full font-[600]">
          Phone Number
        </div>

        <div>
          <PhoneNumber onPhoneNumberChange={handleEmergencyPhoneNumber} />
          {ecErrors.phoneEmergency && (
            <smal className="text-red-500 text-xs flex gap-1 mt-1">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z"
                  stroke="#F94B4B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {ecErrors.phoneEmergency}
            </smal>
          )}
        </div>

        <div className="mt-4 w-full text-base leading-6 text-neutral-600 max-md:max-w-full font-[600]">
          Email Address
        </div>
        <input
          value={emailEmergency}
          onChange={validateEmergencyEmail}
          placeholder="Email Address"
          className="text-black justify-center items-start p-2.5 mt-2 w-full text-base rounded-lg border border-solid border-neutral-300 max-md:pr-5 max-md:max-w-full"
        />
        {ecErrors.emailEmergency && (
          <smal className="text-red-500 text-xs flex gap-1 mt-1">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 5.625V7.96875M13.125 7.5C13.125 8.23869 12.9795 8.97014 12.6968 9.65259C12.4141 10.3351 11.9998 10.9551 11.4775 11.4775C10.9551 11.9998 10.3351 12.4141 9.65259 12.6968C8.97014 12.9795 8.23869 13.125 7.5 13.125C6.76131 13.125 6.02986 12.9795 5.34741 12.6968C4.66495 12.4141 4.04485 11.9998 3.52252 11.4775C3.00019 10.9551 2.58586 10.3351 2.30318 9.65259C2.02049 8.97014 1.875 8.23869 1.875 7.5C1.875 6.00816 2.46763 4.57742 3.52252 3.52252C4.57742 2.46763 6.00816 1.875 7.5 1.875C8.99184 1.875 10.4226 2.46763 11.4775 3.52252C12.5324 4.57742 13.125 6.00816 13.125 7.5ZM7.5 9.84375H7.505V9.84875H7.5V9.84375Z"
                stroke="#F94B4B"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {ecErrors.emailEmergency}
          </smal>
        )}
        {!isValidEmergencyEmail && (
          <div className="text-red-600 text-sm mt-1 px-2">
            Please enter a valid email address.
          </div>
        )}

        <div
          onClick={handleNextBtn}
          className="cursor-pointer text-center justify-center items-center self-end px-9 py-3 mt-8 w-full text-base font-normal text-white whitespace-nowrap bg-blue-900 rounded-[30px] max-md:px-5"
        >
          Next
        </div>
      </div>
    </div>
  );
}

export default PersonalInformation;
