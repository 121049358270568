import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import {
  getFaceRcognitionQR,
  checkFaceRecognitionStatus,
} from "../../api/apiCall";

function FacialRecognition() {
  const [qrCodeSVG, setQrCodeSVG] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  useEffect(() => {
    handleGetFaceRecognitionQR();

    const intervalId = setInterval(() => {
      handleCheckFaceRecognitionStatus();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleGetFaceRecognitionQR = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
        for: "APPLICANT",
      };

      const response = await getFaceRcognitionQR(data);
      if (response.status === 200) {
        setQrCodeSVG(response.data);
      }
    } catch (error) {
      console.error("Failed to get face recognition QR: ", error);
    }
  };

  const handleCheckFaceRecognitionStatus = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
      };

      const response = await checkFaceRecognitionStatus(data);
      if (response.data.message === "Face Recognition Done!") {
        navigate("/interview-process");
        return;
      }
    } catch (error) {
      console.log("Error checking face recognition status: ", error);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-50 px-20 py-20 max-md:px-5">
      <div className="bg-white rounded-lg max-w-[400px] w-full p-6 border border-gray-200 max-md:w-full max-md:px-5">
        <div className="flex flex-col items-center">
          <h2 className="text-lg font-semibold text-gray-700 mb-4">
            Scan QR Code
          </h2>
          <div className="flex justify-center items-center p-1 border-8 border-teal-300">
            <div className="" dangerouslySetInnerHTML={{ __html: qrCodeSVG }} />
          </div>
          <p className="text-center text-sm mt-4">
            Scan this code using your mobile phone to start the facial scanning
            feature
          </p>
          <p className="text-sm mt-4">
            Tip:{" "}
            <span className="text-[#2AD2C9]">
              If the face scanner page didn't load right, please reload this
              page to generate new QR Code.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default FacialRecognition;
