import React, { useRef, useEffect, useState } from "react";
import Webcam from "react-webcam";
import * as faceapi from "face-api.js";
import { useNavigate, useParams } from "react-router-dom";
import { scanAndVerify } from "../../../api/apiCall";
import { useDispatch, useSelector } from "react-redux";

const ScanFace = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");

  const { key } = useParams();
  const webcamRef = useRef(null);
  const [initializing, setInitializing] = useState(true);
  const [capturedImages, setCapturedImages] = useState([]);
  const [lastCapture, setLastCapture] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [scanAndVerifySuccess, setScanAndVerifySuccess] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + "/models";
      await faceapi.loadSsdMobilenetv1Model(MODEL_URL);
      await faceapi.loadFaceLandmarkModel(MODEL_URL);
      await faceapi.loadFaceRecognitionModel(MODEL_URL);
      setInitializing(false);
    };
    loadModels();

    // localStorage.setItem("upload_processing", "false");
  }, []);

  useEffect(() => {
    if (processing) {
      const video = webcamRef.current.video;
      if (video) {
        video.pause();
      }
    }
  }, [processing]);

  const handleVideoOnPlay = () => {
    const video = webcamRef.current.video;

    const captureImage = () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedImages((prev) => {
        const updatedImages = [...prev, imageSrc];
        if (updatedImages.length >= 5) {
          clearInterval(intervalId);
          setProcessing(true);
          uploadImages(updatedImages);
        }
        return updatedImages;
      });
    };

    const checkFaceMovement = (landmarks) => {
      if (lastCapture) {
        const nose = landmarks.getNose();
        const lastNose = lastCapture.getNose();

        const movement = Math.sqrt(
          Math.pow(nose[0].x - lastNose[0].x, 2) +
            Math.pow(nose[0].y - lastNose[0].y, 2)
        );

        return movement >= 3;
      }
      return true;
    };

    const intervalId = setInterval(async () => {
      if (!processing && capturedImages.length < 5) {
        const detections = await faceapi
          .detectAllFaces(video)
          .withFaceLandmarks()
          .withFaceDescriptors();

        if (detections.length > 0 && capturedImages.length < 5) {
          const landmarks = detections[0].landmarks;
          if (capturedImages.length < 3 || checkFaceMovement(landmarks)) {
            captureImage();
            setLastCapture(landmarks);
          }
        }
      }
    }, 1000);

    return () => clearInterval(intervalId);
  };

  const uploadImages = async (images) => {
    if (localStorage.getItem("upload_processing") === "true") return;

    localStorage.setItem("upload_processing", "true");
    try {
      const descriptors = [];
      for (let img of images) {
        const imgElement = new Image();
        imgElement.src = img;
        await new Promise((resolve) => {
          imgElement.onload = async () => {
            const detections = await faceapi
              .detectSingleFace(imgElement)
              .withFaceLandmarks()
              .withFaceDescriptor();

            if (detections) {
              descriptors.push(detections.descriptor);
            }
            resolve();
          };
        });
      }

      if (descriptors.length === 5) {
        let data = {
          payloads: {
            descriptors: descriptors, // Sending new face encodings for verification
            key: key,
          },
        };

        // Send the data to the backend for verification against the .pkl file
        const response = await scanAndVerify(data);
        console.log("FACE API RESPONSE: ", response.data);

        if (response?.data?.message !== "Face Verification Success!") {
          setUploadError(true);
        } else {
          setCapturedImages([]);
          setUploadError(false);
          setScanAndVerifySuccess(true);
          // navigate("/interview");
        }
      } else {
        setUploadError(true);
        console.error("Failed to get 5 valid face descriptors.");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      setUploadError(true);
    } finally {
      setProcessing(false);
      localStorage.setItem("upload_processing", "false");
    }
  };

  const handleRetake = () => {
    setCapturedImages([]);
    setLastCapture(null);
    setProcessing(false);
    setUploadError(false);
    const video = webcamRef.current.video;
    if (video) {
      video.play();
    }
  };

  return (
    <div className="flex flex-col items-center py-32 max-md:py-16 min-h-screen bg-[#1c3775] max-md:px-3">
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col px-7 pb-7 pt-4 w-full mx-3 text-base bg-white rounded-[7px]">
          <div className="flex flex-row items-center justify-between gap-1">
            <img
              onClick={() => navigate("/login")}
              alt="img"
              loading="lazy"
              src="/loginLogo.png"
              className="self-start cursor-pointer max-w-full aspect-[3.85] w-48"
            />
            <h3 className="font-bold text-blue-600">|Scan</h3>
          </div>
          <div className="flex flex-col items-center justify-center py-5">
            {initializing ? (
              <p className="text-lg text-center text-blue-600">
                Loading resources...
              </p>
            ) : (
              <Webcam
                className="rounded-[7px]"
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpg"
                width={420}
                height={320}
                videoConstraints={{ facingMode: "user" }}
                onPlay={handleVideoOnPlay}
              />
            )}
          </div>
          {processing && (
            <div className="mt-1 text-sm text-center text-blue-600">
              Face recognition is validating...
            </div>
          )}
          {!initializing &&
            !uploadError &&
            !processing &&
            !scanAndVerifySuccess && (
              <div className="my-1 text-sm font-bold text-center text-neutral-600">
                Please blink or move slightly.
              </div>
            )}
          {scanAndVerifySuccess && (
            <div className="my-1 text-3xl font-bold text-center text-green-600">
              Verified Successfully!
            </div>
          )}
          {uploadError && (
            <div className="my-1 text-3xl font-bold text-center text-red-600">
              Face Verification Failed!
            </div>
          )}
          {uploadError && !scanAndVerifySuccess && (
            <>
              <button
                onClick={handleRetake}
                className="p-4 mt-3 text-xl text-white bg-blue-500 rounded hover:bg-blue-700"
              >
                Retake
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScanFace;
