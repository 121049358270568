import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsLoading } from "../../../actions";
import BacktoHomepage from "../components/BackToHomePage";
import {
  validateDocument,
  compileDocuments,
  deleteDocument,
  resetDocument,
} from "../../../api/apiCall";
import BacktoLogin from "../components/BackToLogin";

function UploadDocumentsReq() {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const resetData = async () => {
      let data = {
        id: user.id,
        token: token,
      };

      try {
        const response = await resetDocument(data);
        if (response.status === 200) {
          setUploadedFiles([]);
        }
      } catch (error) {
        console.error("Error resetting documents:", error);
      }
    };

    resetData();
  }, []);

  const handleFileUpload = (files) => {
    const newFiles = files.map((file) => ({
      id: Math.random().toString(36).substr(2, 9),
      file,
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = Array.from(event.dataTransfer.files);
    const result = await handleUploadDocument(files[0]);
    if (result) {
      processFiles(files, result);
    }
  };

  const handleInputChange = async (event) => {
    const files = Array.from(event.target.files);
    const result = await handleUploadDocument(files[0]);
    if (result) {
      processFiles(files, result);
    }
  };

  const handleUploadDocument = async (file) => {
    dispatch(setIsLoading(true));
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          document: file,
        },
      };

      const response = await validateDocument(data);
      console.log("Uplaod document response: ", response.data);
      return response.data.data;
    } catch (error) {
      console.error("Error uploading document: ", error);
      return false;
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const processFiles = (files, result) => {
    const newFiles = files.map((file) => ({
      id: Date.now() + file.name,
      name: file.name,
      type: file.type.split("/")[1],
      file: file,
      file_id: result.id,
      file_category: result.type,
      size: formatFileSize(file.size),
    }));
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const formatFileSize = (size) => {
    if (size < 1024) return size + " bytes";
    if (size < 1024 * 1024) return (size / 1024).toFixed(1) + " KB";
    return (size / (1024 * 1024)).toFixed(1) + " MB";
  };

  const deleteFile = async (file_id) => {
    console.log("ID: ", file_id);
    dispatch(setIsLoading(true));
    try {
      let data = {
        id: user.id,
        token: token,
        documentId: file_id,
      };

      const response = await deleteDocument(data);
      if (response.status === 200) {
        setUploadedFiles(
          uploadedFiles.filter((file) => file.file_id !== file_id)
        );
      }
    } catch (error) {
      console.log("Error deleting document: ", error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleCompileDocuments = async () => {
    dispatch(setIsLoading(true));
    try {
      let data = {
        id: user.id,
        token: token,
      };

      const response = await compileDocuments(data);
      if (response.status === 200) {
        setUploadedFiles([]);
        navigate("/upload-success");
      }
    } catch (error) {
      console.error("Error compiling documents: ", error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const getFileIcon = (fileType) => {
    switch (fileType) {
      case "image/jpeg":
      case "image/png":
        return "/img.png";
      case "application/pdf":
        return "/pdf.png";
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "/doc.png";
      default:
        return "/file-icon.png";
    }
  };

  const truncateMessage = (message, maxLength = 10) => {
    return message.length > maxLength
      ? message.substring(0, maxLength) + "..."
      : message;
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-devRegDarkBlue">
      <div className="flex flex-col p-6 max-md:px-4 max-w-[750px] bg-white rounded-3xl w-full">
        <div className="mt-4 text-lg sm:text-xl font-semibold text-neutral-800">
          Document Requirements
        </div>
        <div className="mt-3 text-sm sm:text-base text-zinc-700 text-opacity-80">
          Please upload the requested documents sent to your email
        </div>
        <div
          className={`flex flex-col justify-center mt-6 border-2 border-dashed min-h-[300px] border-gray-300 px-6 py-10 rounded-md text-center cursor-pointer ${
            isDragging ? "bg-gray-200" : ""
          }`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            type="file"
            multiple
            className="hidden"
            id="fileUpload"
            accept=".jpg,.png,.pdf,.doc,.docx"
            onChange={handleInputChange}
          />
          <label htmlFor="fileUpload" className="cursor-pointer text-gray-500">
            Drag or click to upload your documents here
            <br />
            <span className="text-xs sm:text-sm text-gray-500">
              (jpg, png, pdf, doc, docx)
            </span>
          </label>
        </div>
        {uploadedFiles.length > 0 && (
          <div className="mt-6">
            <ul className="m-auto grid grid-cols-1 justify-center sm:grid-cols-2 gap-3">
              {uploadedFiles.map((file, index) => (
                <li
                  key={index}
                  className="flex justify-center items-center space-x-2 p-2 m-auto"
                >
                  <img
                    src={getFileIcon(file.file.type)}
                    alt="file icon"
                    className="w-10 h-10 sm:w-12 sm:h-12"
                  />
                  <div className="flex flex-col">
                    <span className="file-name text-sm sm:text-base text-gray-800">
                      {truncateMessage(file.file.name, 20)}
                    </span>
                    <span className="file-size text-xs sm:text-sm text-gray-500">
                      {formatFileSize(file.file.size)}/
                      {formatFileSize(file.file.size)}
                    </span>
                  </div>
                  <button
                    className="text-red-500 text-xs sm:text-sm"
                    onClick={() => deleteFile(file.file_id)}
                  >
                    <FaTimes />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="flex flex-col sm:flex-row justify-between gap-4 sm:gap-4 items-center mt-6">
          <button className="cursor-pointer flex-grow w-full sm:w-1/2 md:w-40 px-4 py-3 text-sm sm:text-base font-normal text-blue-900 whitespace-nowrap rounded-3xl bg-blue-100">
            Cancel
          </button>
          <button
            className="cursor-pointer flex-grow w-full sm:w-1/2 md:w-40 px-4 py-3 text-sm sm:text-base font-normal text-white whitespace-nowrap rounded-3xl bg-blue-900"
            onClick={handleCompileDocuments}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default UploadDocumentsReq;
